.private-home-page .right-panel {
  background-image: url("/images/covid_2.jpg");
  padding: 0;
  border-radius: 12px;
}
.private-home-page .right-panel .about-right-box {
  background-color: #532565;
  color: white;
  opacity: 0.9;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 30px 40px;
  z-index: 1;
  width: 100%;
  border-radius: 12px;
}
.private-home-page .right-panel .about-right-box .statistic-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.private-home-page .right-panel .about-right-box .statistic-block.last {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.private-home-page .right-panel .about-right-box .statistic-block .title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 30px;
}
.private-home-page .right-panel .about-right-box .statistic-block .count {
  margin-left: auto;
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}